<script setup></script>

<template>
    <div class="w-full black text-white flex flex-col items-center justify-center px-3">
        <div>
            <h1 class="text-2xl md:text-3xl mb-10 border-b-2">Termine & Öffnungszeiten</h1>
        </div>
        <div class="text-xl flex flex-col items-center justify-center">
            <p class="mb-10 border-b">Öffnungszeiten</p>
            <p class="pb-2">Montag - Freitag 17:00 bis 20:00 Uhr</p>
            <p class="pb-10">Samstag 09:00 bis 13:00 Uhr</p>
        </div>
        <div class="text-xl flex flex-col items-center justify-center">
            <p class="mb-10 border-b">Terminvereinbarung</p>
            <p class="pb-2">Mobil: 0151 / 51047850</p>
            <p class="pb-2">Festnetz: 08291 / 169680</p>
            <p class="pb-2">E-Mail: info@auto-wall.de</p>
            <p>Gerne auch per WhatsApp</p>
        </div>
    </div>
</template>

<style></style>