import { defineStore } from "pinia";
import { ref } from "vue";

export const useCalculatorStore = defineStore("calculatorStore", () => {
  const prices = {
    raederBis16Zoll: 20.0,
    raederBis20Zoll: 25.0,
    raederRunflat: 28.0,
    aluAufpreis: 3.0,
    altreifenentsorgung: 5.0,
    fremdRDKS: 5.0,
    komplettradwäsche: 2.5,
    nurWuchten: 6.0,
    reifenummontieren: 7.0,
    felgeEntsorgen: 5.0,
    radwechselEinzeln: 8.75,
    einlagerung: 5.0,
    erschwerteMontage: 5.0,
  };

  const amounts = ref({
    raederBis16Zoll: 0,
    raederBis20Zoll: 0,
    raederRunflat: 0,
    aluAufpreis: 0,
    altreifenentsorgung: 0,
    fremdRDKS: 0,
    komplettradwäsche: 0,
    nurWuchten: 0,
    reifenummontieren: 0,
    felgeEntsorgen: 0,
    radwechselEinzeln: 0,
    einlagerung: 0,
    erschwerteMontage: 0,
  });

  function calculatePrices() {
    let total = 0;
    for (const key in amounts.value) {
      if (amounts.value[key] > 0) {
        total += amounts.value[key] * prices[key];
      }
    }

    return total;
  }

  function changeAmount(key, operation) {
    if (operation === "increase") {
      amounts.value[key]++;
    }

    if (operation === "decrease" && amounts.value[key] > 0) {
      amounts.value[key]--;
    }
  }

  return {
    prices,
    amounts,
    calculatePrices,
    changeAmount,
  };
});
