<script setup>
function scrollDown() {
    if (window.innerWidth < 600) {
        window.scrollBy({ top: 1560, behavior: 'smooth' });
    } else {
        window.scrollBy({ top: 910, behavior: 'smooth' });
    }
}
</script>

<template>
    <div class="w-full black text-white p-10 flex items-center justify-center">
        <button @click="scrollDown"
            class="border-2 rounded-3xl px-10 py-3 hover:bg-white hover:text-black transition-all duration-500">
            Jetzt Termin vereinbaren
        </button>
    </div>
</template>

<style scoped></style>