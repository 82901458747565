<script setup></script>

<template>
    <div class="w-full h-[100vh] black text-white p-10 flex flex-col items-center justify-center">
        <p class="text-3xl pb-10">Impressum</p>
        <p class="text-xl pb-px">Der Betreiber dieser Homepage ist die Firma Automobile Wall</p>
        <p class="text-xl pb-px pt-10">Inhaber</p>
        <p class="text-xl pb-px">Markus Wall</p>
        <p class="text-xl pb-px">Dorfstraße 44a</p>
        <p class="text-xl pb-px">86441 Steinekirch</p>
        <p class="text-xl pb-px pt-10">Tel.: 08291 / 169680</p>
        <p class="text-xl pb-px">Mobil: 0151 / 51047850</p>
        <p class="text-xl pb-px">Email: info@auto-wall.de</p>
        <RouterLink to="/">
            <button
                class="w-44 mt-10 border-2 rounded-3xl p-3 hover:bg-white hover:text-black transition-all duration-500">
                Zurück
            </button>
        </RouterLink>
    </div>
</template>

<style scoped></style>