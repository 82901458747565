<script setup>

</script>

<template>
    <div class="w-full black text-white flex flex-col items-center justify-center pb-10">
        <div>
            <h1 class="text-3xl pt-20 mb-10 border-b-2">Standort</h1>
        </div>
        <div>
            <p class="text-xl pb-6 text-center mx-3">Sie finden uns in der Dorfstraße 44a, 86441 Steinekirch</p>
        </div>
        <a href="https://www.google.de/maps/place/Auto+Wall+Reifenservice/@48.3693976,10.577272,17.25z/data=!4m6!3m5!1s0x479eadc333bbecd9:0xbcdde0a6b3014379!8m2!3d48.36933!4d10.57858!16s%2Fg%2F1td9pj9h?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D"
            class="rounded-3xl overflow-hidden hover:cursor-pointer mx-3" target="_blank">
            <img src="../assets/standort.png" alt="standort">
        </a>
    </div>
</template>

<style scoped></style>