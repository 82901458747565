<script setup>
import { ref, computed } from "vue";
import { useCalculatorStore } from "../stores/calculatorStore";

const calculatorStore = useCalculatorStore();

const shortServicesNames = ref([
    'Raeder 16"',
    'Raeder 20"',
    "Runflat",
    "Alu",
    "Altreif.",
    "RDKS",
    "Radwäsche",
    "Wuchten",
    "Ummontieren",
    "Felge ents.",
    "RW Einzel",
    "Einlagerung",
    "ErschwMon",
]);

const results = computed(() => {
    return Object.keys(calculatorStore.amounts)
        .map((key) => {
            const amount = calculatorStore.amounts[key];
            const price = calculatorStore.prices[key];
            const shortNameIndex = Object.keys(calculatorStore.amounts).indexOf(key);
            const total = amount * price;
            const netto = total / 1.19;
            return {
                name: shortServicesNames.value[shortNameIndex],
                amount,
                price,
                total,
                netto,
            };
        })
        .filter((item) => item.amount > 0);
});


const totalNetto = computed(() => {
    return results.value.reduce((sum, item) => sum + item.netto, 0).toFixed(2);
});

const totalBrutto = computed(() => {
    return results.value.reduce((sum, item) => sum + item.total, 0).toFixed(2);
});
</script>

<template>
    <div class="w-full mb-3 min-h-[800px]">
        <div id="header" class="flex items-center justify-between px-3 mb-3">
            <span class="text-center w-1/4 font-bold text-xl">Leistung</span>
            <span class="text-center w-1/4 font-bold text-xl">Anzahl</span>
            <span class="text-center w-1/4 font-bold text-xl">Netto</span>
            <span class="text-center w-1/4 font-bold text-xl">Brutto</span>
        </div>
        <div id="listing" class="flex flex-col items-center justify-between px-3 border-b-2 border-black pb-3">
            <div v-for="item in results" :key="item.name" class="flex w-full justify-between py-2">
                <p class="text-center w-1/4">{{ item.name }}</p>
                <p class="text-center w-1/4">{{ item.amount }}</p>
                <p class="text-center w-1/4">{{ item.netto.toFixed(2) }} €</p>
                <p class="text-center w-1/4">{{ item.total.toFixed(2) }} €</p>
            </div>
        </div>
        <div id="total" class="flex justify-between px-3 py-2 font-bold text-xl pt-3">
            <span class="text-center w-1/4">Gesamt</span>
            <span class="text-center w-1/4"></span>
            <span class="text-center w-1/4">{{ totalNetto }} €</span>
            <span class="text-center w-1/4 double-underline">{{ totalBrutto }} €</span>
        </div>
    </div>
</template>


<style scoped>
.double-underline {
    border-bottom: 5px double black;
}

p {
    font-size: 18px;
}
</style>
