<script setup>
import { nextTick, ref } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCalculatorStore } from "../stores/calculatorStore";
import Results from "@/components/Results.vue";

library.add(faPlus, faMinus, faArrowRight);

const calculatorStore = useCalculatorStore();

const showResults = ref(false);

const amounts = ref({
  raederBis16Zoll: 0,
  raederBis20Zoll: 0,
  raederRunflat: 0,
  zuschlagAlu: 0,
  altreifenentsorgung: 0,
  fremdRDKS: 0,
  komplettradwaesche: 0,
  wuchtenExclusiv: 0,
  reifenummontieren: 0,
  felgeEntsorgen: 0,
  radwechselEinzeln: 0,
  einlagerung: 0,
  erschwerteMontage: 0,
});

function calculatePrices() {
  showResults.value = true;

  nextTick(() => {
    window.scrollBy({ top: 500, behavior: 'smooth' });
  })
}

function capitalizeWords(str) {
  return str.replace(/(?:^|\s)\S/g, char => char.toUpperCase())
    .replace(/Komplettradwäsche/, 'Komplettradwäsche')
    .replace(/Reifenummontieren/, 'Reifen ummontieren')
    .replace(/Bis(\d+)/g, 'Bis $1');
}

function reset() {
  showResults.value = false;

  Object.keys(calculatorStore.amounts).forEach(key => {
    calculatorStore.amounts[key] = 0;
  });

  nextTick(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
}
</script>

<template>
  <div id="wrapper" class="w-full h-full flex items-center justify-center md:w-[100%]">
    <RouterLink to="/">
      <button class="h-14 w-14 border-2 black absolute top-3 left-3 rounded-full flex items-center justify-center">
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-white rotate-180 text-xl" />
      </button>
    </RouterLink>

    <div class="flex flex-col items-center justify-center">
      <h1 class="text-3xl p-6 font-bold">Preise</h1>
      <div v-for="(amount, key) in calculatorStore.amounts" :key="key" class="mb-4 w-full">
        <div class="flex w-full relative mt-6 items-center">
          <p class="ml-3">{{ capitalizeWords(key.replace(/([A-Z])/g, " $1").trim()) }}</p>
          <div class="buttons-wrapper flex gap-2 absolute right-2">
            <button @click="calculatorStore.changeAmount(key, 'decrease')"
              class="black rounded-xl text-white w-[50px] h-[50px] flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'minus']" />
            </button>
            <p class="w-[50px] h-[50px] rounded-xl flex items-center justify-center border">
              {{ amount }}
            </p>
            <button @click="calculatorStore.changeAmount(key, 'increase')"
              class="black text-white w-[50px] rounded-xl h-[50px] flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </button>
          </div>
        </div>
      </div>

      <div id="buttons" class="w-full flex items-center justify-center gap-4 my-10 text-2xl">
        <button @click="reset"
          class="text-white font-bold h-32 w-[45%] rounded-xl bg-red-400 active:bg-red-500 border-4 border-red-500">
          Zurücksetzen
        </button>
        <button @click="calculatePrices"
          class="text-white font-bold h-32 w-[45%] rounded-xl bg-green-400 active:bg-green-500 text-2xl border-4 border-green-500">
          Preise berechnen
        </button>
      </div>

      <Results v-if="showResults"></Results>
    </div>
  </div>
</template>

<style scoped>
p {
  font-size: 19px;
  font-weight: bold;
}

button:active {
  transform: translateY(2px);
}
</style>