<script setup>
import { RouterLink, useRouter } from 'vue-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalculator, faCar, faWrench, faListCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ref, onMounted, onUnmounted } from 'vue';

library.add(faListCheck, faCar, faWrench, faCalculator, faArrowRight);

const showModal = ref(false);
const enteredPin = ref('');
const correctPin = '1307';
const router = useRouter();

function toggleModal() {
    showModal.value = !showModal.value;
}

function checkPin() {
    if (enteredPin.value.toString() === correctPin) {
        router.push('/Calculator');
    } else {
        alert('Falscher PIN. Bitte versuche es erneut.');
    }
}

const isMobile = ref(false);

function updateIsMobile() {
    isMobile.value = window.innerWidth < 1000;
}

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile();
})

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
})
</script>

<template>
    <div v-if="!isMobile" @click="toggleModal">
        <section>
            <div class="slider">
                <div class="slider-items">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                    <img src="../assets/Automobile-schlank.png" alt="logo">
                </div>
            </div>
        </section>
    </div>
    <div v-if="isMobile" @click="toggleModal"
        class="h-[10vh] black border-t-2 border-white flex items-center justify-center text-white">
        <RouterLink to="/Impressum" class="hover:text-gray-400">Impressum</RouterLink>
    </div>

    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white p-8 rounded shadow-lg max-w-md w-full relative flex flex-col items-center justify-center">
            <button @click="toggleModal"
                class="w-[30px] h-[30px] bg-red-500 rounded-full absolute top-2 right-2 text-white">×</button>
            <p class="p-3">PIN</p>
            <div class="flex items-center border-2 border-black rounded-full overflow-hidden px-0.5">
                <input type="number" v-model="enteredPin"
                    class="flex-grow p-3 border-none focus:outline-none hide-arrows">
                <button @click="checkPin" class="bg-black text-white p-3 rounded-full flex items-center justify-center">
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
section {
    display: grid;
    place-items: center;
    background-color: rgb(40, 40, 40);
    border-top: 2px solid white;
}

.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    overflow: hidden;
    background-color: rgb(40, 40, 40);
    padding-top: 20px;
    padding-bottom: 20px;
}

.slider-items {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: scrolling 20s linear infinite;
}

@keyframes scrolling {
    0% {
        transform: translateX(80%);
    }

    100% {
        transform: translateX(-20%);
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
