<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const isMobile = ref(false);

function scrollToServices() {
    window.scrollBy({ top: 400, behavior: 'smooth' });
}

function scrollToAppointments() {
    window.scrollBy({ top: 920, behavior: 'smooth' });
}

function scrollToLocation() {
    window.scrollBy({ top: 1600, behavior: 'smooth' });
}

function updateIsMobile() {
    isMobile.value = window.innerWidth < 1000;
}

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile();
})

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
})
</script>

<template>
    <div class="w-full h-[10vh] black flex items-center justify-center border-b">
        <RouterLink to="/">
            <div class="hover:cursor-pointer">
                <img src="../assets/Automobile-schlank.png" alt="logo">
            </div>
        </RouterLink>
        <div v-if="!isMobile" class="flex gap-6 ml-4 text-white text-xl">
            <RouterLink to="/">
                <p class="cursor-pointer border-b-2 border-transparent hover:border-white">Start</p>
            </RouterLink>
            <p @click="scrollToServices" class="cursor-pointer border-b-2 border-transparent hover:border-white">
                Leistungen</p>
            <p @click="scrollToAppointments" class="cursor-pointer border-b-2 border-transparent hover:border-white">
                Termine</p>
            <p @click="scrollToLocation" class="cursor-pointer border-b-2 border-transparent hover:border-white">
                Standort</p>
            <RouterLink to="/Impressum">
                <p class="cursor-pointer border-b-2 border-transparent hover:border-white">Impressum</p>
            </RouterLink>
        </div>
    </div>
</template>

<style>
.black {
    background-color: rgb(40, 40, 40);
}

.black:active {
    background-color: rgb(60, 60, 60);
}
</style>
