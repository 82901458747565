<script setup>
import { ref, onMounted } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { onUnmounted } from 'vue';

library.add(faListCheck, faCar, faWrench);

const show = ref([false, false, false]);

const isMobile = ref(false);

onMounted(() => {
    setTimeout(() => { show.value[0] = true }, 1000);
    setTimeout(() => { show.value[1] = true }, 1500);
    setTimeout(() => { show.value[2] = true }, 2000);
});

function updateIsMobile() {
    isMobile.value = window.innerWidth < 1000;
}

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile();
})

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
})
</script>

<template>
    <div class="w-full black text-white text-3xl flex items-center justify-center pt-10">
        <h1 class="border-b-2">Leistungen</h1>
    </div>
    <div :class="isMobile ? 'flex flex-col' : 'flex'" class="black flex justify-center items-center gap-6 py-10">
        <div :class="show[0] ? 'opacity-100' : 'opacity-0', isMobile ? 'w-80 h-80' : 'w-96 h-96'"
            class="lighter-black border-2 rounded-3xl text-white flex flex-col items-center justify-center transition-opacity duration-1000">
            <div>
                <font-awesome-icon :icon="['fas', 'wrench']" class="text-4xl" />
            </div>
            <div>
                <p class="text-3xl pb-10 pt-3">Radwechsel</p>
            </div>
            <div>
                <ul class="list-disc">
                    <li>Radwechsel zum Bestpreis</li>
                    <li>Kostenlose Reifenwäsche</li>
                    <li>Fachgerechte Reifeneinlagerung</li>
                </ul>
            </div>
        </div>
        <div :class="show[1] ? 'opacity-100' : 'opacity-0', isMobile ? 'w-80 h-80' : 'w-96 h-96'"
            class="lighter-black border-2 rounded-3xl text-white flex flex-col items-center justify-center transition-opacity duration-1000">
            <div>
                <font-awesome-icon :icon="['fas', 'list-check']" class="text-4xl" />
            </div>
            <div>
                <p class="text-3xl pb-10 pt-3">Reifenservice</p>
            </div>
            <div>
                <ul class="list-disc">
                    <li>Neue Felgen und Kompletträder</li>
                    <li>Reifenmontage und Wuchten</li>
                    <li>Reifendruckkontrollsystem</li>
                </ul>
            </div>
        </div>
        <div :class="show[2] ? 'opacity-100' : 'opacity-0', isMobile ? 'w-80 h-80' : 'w-96 h-96'"
            class="lighter-black border-2 rounded-3xl text-white flex flex-col items-center justify-center transition-opacity duration-1000">
            <div>
                <font-awesome-icon :icon="['fas', 'car']" class="text-4xl" />
            </div>
            <div>
                <p class="text-3xl pb-10 pt-3">Automarkt</p>
            </div>
            <div>
                <ul class="list-disc">
                    <li>Inzahlungnahme alter Fahrzeuge</li>
                    <li>PKW Vermittlung</li>
                    <li>Kaufberatung</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
.lighter-black {
    background-color: rgb(50, 50, 50);
}
</style>
